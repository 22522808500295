<template lang="html">
    <section class="feed-twitter">
        <div class="content">
            <section class="head">
                <p class="title">Diario Cermi</p>

                <a href="https://twitter.com/Cermi_Estatal" title="Enlace seguir twitter" target="_blank"
                    class="btn-white">Seguir</a>
            </section>

            <twitter>
                <a class="twitter-timeline" title="Enlace seguir twitter" href="https://twitter.com/Cermi_Estatal?ref_src=twsrc%5Etfw">Tweets by
                    Cermi_Estatal</a>
            </twitter>

        </div>
    </section>
</template>
<script lang="js">
    import { twitter } from 'vue-twitter'
    export default {
        name: 'feed-twitter',
        props: [],
        components: {
            twitter
        },
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .feed-twitter {
        margin-top: 40px;
        margin-top: 40px;
        height: 655px;
        overflow-y: scroll;
        border-radius: 13px;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar-button:increment,
        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar:horizontal {
            height: 5px;
            margin: 5px 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blue;
            border-radius: 50px;
            border: 2px solid $blue;
        }

        &::-webkit-scrollbar-track {
            border-radius: 50px;
        }

        .content {
            background: $blueLight;
            border-radius: 13px;
            padding: 20px;

            .head {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;

                .title {
                    position: relative;
                    margin-bottom: 0;
                    padding-left: 48px;

                    &:before {
                        content: '';
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        background: url('/img/icons/icon-twitter.svg');
                        background-size: 38px;
                        background-position: center center;
                        position: absolute;
                        left: 0;
                        top: -12px;
                    }
                }

                .btn-white {
                    background-color: transparent;
                    color: $blue;
                    border-color: $blue;

                    &:hover {
                        color: white;
                        border-color: $blue;
                        background-color: $blue;
                    }
                }
            }

            .list-tweet {
                padding-left: 0;

                li {
                    list-style: none;
                    padding: 20px 0;
                    border-bottom: 1px solid $blue;
                    margin-bottom: 10px;
                }
            }
        }
    }

    @media screen and (max-width:1060px) {
        .feed-twitter {
            height: auto;
        }

    }

    @media screen and (max-width: 845px) {
        .feed-twitter {
            height: auto;
            width: 100%;
        }
    }
</style>